.branch-main-container{
    height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
  margin-top: 15vh;
}

.branch-main-warp{
    /* make it fit content after making layouts */
    height: 100vh;
    width: 100%;
    /* background: rgb(232, 23, 23); */
    display: flex;
    align-items: center;
    justify-content: center;
    /* flex-direction: column; */
    /* gap: 30px; */
}

.branch-main-warp-left-container{
    height: 100%;
    width: 50%;
    margin: 10px;
    /* background: rgb(209, 191, 191); */
}

.branch-left-warp{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
}

.branch-title {
    height: 25%;
    width: 100%;
    background: var(--template-color);
    display: flex;
    align-items: center;
    justify-content: center;
    font: var(--primary-font);
    font-weight: 500;
    cursor: pointer;
    color: var(--secondary-font-color);
    font-size: var(--font-size-2);
}

.location-container{
    height: 25%;
    width: 100%;
    background: rgb(233, 231, 231);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}

.location-left-container{
    height: 100%;
    width: 80%;
    padding-left: 10px;
    /* background: rgb(230, 198, 198); */
}

.branch-place{
    font: var(--primary-font);
    font-weight: 500;
    cursor: pointer;
    color: var(--primary-font-color);
    font-size: var(--font-size-2);
}

.branch-address{
    font: var(--primary-font);
    font-weight: 200;
    cursor: pointer;
    color: var(--primary-font-color);
    font-size: var(--font-size-4);
}


.location-right-container{
    height: 100%;
    width: 20%;
    /* background: rgb(173, 23, 23); */
    display: flex;
    align-items: center;
    justify-content: center;
}

.branch-main-warp-right-container{
    height: 100%;
    width: 50%;
    margin: 10px;
    /* background: rgb(161, 21, 21); */
}

@media only screen and (max-device-width: 760px) {
    .branch-main-warp{
        flex-direction: column-reverse;
    }
    .branch-main-container{
        height: auto;
    }
    .branch-main-warp-left-container, .branch-main-warp-right-container{
        width: 90%;
    }
}