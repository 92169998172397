.product-main-container{
    height: fit-content;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
  margin-top: 15vh;
}

.product-main-warp{
    /* make it fit content after making layouts */
    height: fit-content;
    width: 100%;
    /* background: rgb(232, 23, 23); */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
}

.product-title-container{
    height: fit-content;
    width: 90%;
}

.product-title{
    font: var(--primary-font);
    font-weight: 700;
    cursor: pointer;
    color: var(--primary-font-color);
    font-size: var(--font-size-1);
    text-align: left;
}

.product-container{
    height: 100%;
    width: 90%;
    /* background: rgb(235, 235, 235); */
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8vw;
    flex-wrap: wrap;
}