.category-main{
    height: fit-content;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 3vh;
    /* background: #000; */
}

.category-title-container{
    height: 20%;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.category-heading{
    font: var(--primary-font);
    font-weight: 700;
    cursor: pointer;
    color: var(--primary-font-color);
    font-size: var(--font-size-1);
    text-align: left;
}

.category-main-container{
    height: 80%;
    width: 90%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 10vw;
}

.category-view-more{
    font: var(--primary-font);
    font-weight: 500;
    cursor: pointer;
    color: var(--primary-font-color);
    font-size: var(--font-size-3);
    text-align: left;
    text-decoration: underline;
}

.category-view-more:hover{
    color:var(--template-color);
    font-size: var(--font-size-2);
    font-weight: 700;
    text-decoration: none;
}


@media only screen and (max-device-width: 760px) {
    .category-main-container{
        flex-direction: column;
    }
}