.productcard-main-container{
    height: fit-content;
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    /* background: #000; */
}

.productcard-image-container{
    /* height: 70%; */
    height: 40vh;
    width: 100%;
    /* background: rgb(11, 83, 164); */
}

.product-image{
    height: 100%;
    width: 100%;
    object-fit:contain
}

.productcard-content-container{
    height: fit-content;
   
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    /* background: rgb(36, 176, 21); */
}

.productcard-content-left{
    height: fit-content;
    width: 70%;
    /* background: rgb(90, 90, 90); */
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 10px;

}

.productcard-content-title{
    font: var(--primary-font);
    font-weight: 700;
    cursor: pointer;
    color: var(--primary-font-color);
    font-size: var(--font-size-2);
    text-align: left;
}

.productcard-content-description{
    min-height: 5vh;
    font: var(--primary-font);
    font-weight: 400;
    cursor: pointer;
    color: var(--primary-font-color);
    font-size: var(--font-size-4);
    text-align: left;
}

.productcard-content-price{
    font: var(--primary-font);
    font-weight: 400;
    cursor: pointer;
    color: var(--primary-font-color);
    font-size: var(--font-size-3);
    text-align: left;
}

.productcard-content-right{
    height: fit-content;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: rgb(223, 27, 27);
}

.product-card-button{
    width: fit-content;
    font: var(--primary-font);
    font-weight: 400;
    cursor: pointer;
    color: white;
    font-size: var(--font-size-3);
    background: var(--template-color);
    padding: 5px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
}

.productcard-button-container{
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.productcard-icon-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.iconStyle{
    color: #28A3B2; 
    cursor:"pointer"
}

@media only screen and (max-device-width: 760px) {
    .productcard-main-container{
        width: 90%;
    }
}