.home-main-container{
    height: fit-content;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10vh;
  margin-top: 15vh;
  /* background: #000; */
}

@media only screen and (max-device-width: 760px) {
  .home-main-container{
      margin-top:10vh;
  }
}