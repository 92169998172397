.map-main-container{
    height: 50vh;
    width: 90%;
    background: #000;
}

.map{
    height: 100%;
    width: 100%;
    flex-grow: 1; border: none; margin: 0; padding: 0;
}

