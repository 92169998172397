.discountsection1-main-container{
    height: 60vh;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row-reverse;
    /* background: rgb(118, 4, 4); */

    /* background: #000; */
}

.discountsection1-left-container{
    width: 70%;
    height: 100%;
    /* background: rgb(137, 135, 135); */
}

.discountsection1-image{
    height: 100%;
    width: 100%;
    object-fit: cover
}

.discountsection1-right-container{
    width: 30%;
    height: 100%;
    background: #F1F1F1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
}

.discountsection1-right-content{
    width: 80%;
    font: var(--primary-font);
    font-weight: 400;
    cursor: pointer;
    color: var(--primary-font-color);
    font-size: var(--font-size-4);
    text-align: center;
}
.discountsection1-right-applink-container{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
}

.discountsection1-right-title{
    /* width: 100%; */
    /* height: fit-content;
    width: fit-content; */
    font: var(--primary-font);
    font-weight: 900;
    cursor: pointer;
    /* color: white; */
    color: var(--primary-font-color);
    font-size: var(--font-size-1);
    /* background: var(--template-color); */
    /* border-radius: 5px; */
    /* padding: 5px 20px 5px 20px; */
    /* text-align: center; */
}

.applink{
    height: 30px;
    width: 30px;
}

.applink-container{
    height: fit-content;
    width: fit-content;
    background: #fff;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    cursor: pointer;
}

@media only screen and (max-device-width: 760px) {
    .discountsection1-main-container{
        flex-direction: column-reverse;
    }
    .discountsection1-left-container, .discountsection1-right-container{
        width: 100%;
    }
}