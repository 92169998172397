.custom-header-menu-container{
    display: flex;
    gap: 35px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 15px;
}

.custom-header-menu-item{
    font: var(--primary-font);
    font-weight: 500;
    cursor: pointer;
    color: var(--primary-font-color);
    font-size: var(--font-size-4);
}

.ant-menu-item-selected, .ant-menu-submenu-selected {
    color: var(--template-color) !important;
    /* font-size: var(--font-size-2) !important; */
    border-bottom: none !important;
  }

.ant-menu-horizontal > .ant-menu-item::after, .ant-menu-horizontal > .ant-menu-submenu::after{
    content: none !important;
}

.ant-menu-horizontal{
    font: var(--primary-font) !important;
    font-weight: 500 !important;
    cursor: pointer !important;
    color: var(--primary-font-color) !important;
    font-size: var(--font-size-3) !important;
    border-bottom: none !important;
    width: 100% !important;
    /* background-color:  rgb(187, 187, 187) !important; */
}

.ant-menu-light .ant-menu-item:hover, .ant-menu-light .ant-menu-item-active, .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-light .ant-menu-submenu-active, .ant-menu-light .ant-menu-submenu-title:hover{
    color: var(--template-color) !important;
    /* font-size: var(--font-size-2) !important; */
}