.categorycard-main-container{
    /* height: 70%; */
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    cursor: pointer;
    /* background: #000; */
}

.categorycard-image-container{
    height: 40vh;
    width: 100%;
    /* background: rgb(11, 83, 164); */
}

.category-image{
    height: 100%;
    width: 100%;
    object-fit: contain
}

.categorycard-content-container{
    height: fit-content;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    /* background: rgb(36, 176, 21); */
}

.categorycard-content-left{
    height: fit-content;
    width: 100%;
    /* background: rgb(90, 90, 90); */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;

}

.categorycard-content-title{
    font: var(--primary-font);
    font-weight: 700;
    cursor: pointer;
    color: var(--primary-font-color);
    font-size: var(--font-size-2);
    text-align: center;
}

.categorycard-content-description{
    min-height: 5vh;
    font: var(--primary-font);
    font-weight: 400;
    cursor: pointer;
    color: var(--primary-font-color);
    font-size: var(--font-size-4);
    text-align: center;
}

.categorycard-content-price{
    font: var(--primary-font);
    font-weight: 400;
    cursor: pointer;
    color: var(--primary-font-color);
    font-size: var(--font-size-3);
    text-align: left;
}


@media only screen and (max-device-width: 760px) {
    .categorycard-main-container{
        width: 90%;
    }
}