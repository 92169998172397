.admin-contact-main-container{
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content:center;
}

.admin-contact-container{
    height: 100%;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
}