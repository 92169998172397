.browse-main-container{
    height: 60vh;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: #000; */
}

.browser-left-container{
    width: 70%;
    height: 100%;
    /* background: rgb(137, 135, 135); */
}

.browse-image{
    height: 100%;
    width: 80%;
    object-fit: cover;
    border-radius: 15px;
}

.browser-right-container{
    width: 30%;
    height: 100%;
    /* background: rgb(118, 4, 4); */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
}

.browse-right-content{
    font: var(--primary-font);
    font-weight: 700;
    cursor: pointer;
    color: var(--primary-font-color);
    font-size: var(--font-size-2);
    text-align: center;
}

.browse-right-button{
    /* width: 100%; */
    height: fit-content;
    width: fit-content;
    font: var(--primary-font);
    font-weight: 400;
    cursor: pointer;
    color: white;
    font-size: var(--font-size-3);
    background: var(--template-color);
    border-radius: 5px;
    padding: 5px 20px 5px 20px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

@media only screen and (max-device-width: 760px) {
    .browse-main-container{
        flex-direction: column;
    }
    .browser-left-container{
        width: 100%;
    }
    .browser-right-container{
        width: 90%;
        height: fit-content;
        padding: 3vh;
    }
}