.sidebar-main-container{
    height: 100%;
    width: 20%;
    background: #ffffff;
    box-shadow:  20px 20px 60px #d9d9d9,
             -20px -20px 60px #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.sidebar-logo-container{
    height: 20%;
    width: 100%;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logoImage{
    height: 50%;
}

.sidebar-menu-container{
    height: 80%;
    width: 100%;
    /* background: rgb(35, 179, 102); */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 15px;
    margin-top: 15px;
}

.sidebar-menu-item{
    font: var(--primary-font);
    font-weight: 600;
    cursor: pointer;
    color: var(--primary-font-color);
    font-size: var(--font-size-4);
    text-align: left;
}