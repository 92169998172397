.admin-home-main-container{
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content:center;
}

.admin-home-container{
    height: 100%;
    width: 80%;
    overflow-y: scroll;
    /* background: rgb(181, 51, 51); */
}