.contact-details-main-container{
    height: 60vh;
    width: 90%;
    /* background: rgb(215, 215, 215); */
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact-details-main-left{
    height: 100%;
    width: 50%;
    /* background: rgb(182, 178, 178); */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.contact-details-main-left-warp{
    height: 100%;
    width: 90%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    /* background: rgb(185, 185, 185); */
}
.contact-form-title{
    font: var(--primary-font);
    font-weight: 700;
    cursor: pointer;
    color: var(--primary-font-color);
    font-size: var(--font-size-1);
    text-align: left;
}

.contact-details-title{
    font: var(--primary-font);
    font-weight: 900;
    cursor: pointer;
    color: var(--primary-font-color);
    font-size: var(--font-size-1);
    text-align: left;
}

.contact-details-content{
    font: var(--primary-font);
    font-weight: 400;
    cursor: pointer;
    color: var(--primary-font-color);
    font-size: var(--font-size-3);
    text-align: left;
}

.contact-details-main-right{
    height: 100%;
    width: 50%;
    /* background: rgb(224, 231, 224); */
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-container {
    height: fit-content;
    width: 100%;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
    padding: 15px;
    /* background: rgb(222, 222, 222); */
  }
  
  .contact-form-input-warp {
    height: 10vh;
    width: 50%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    /* background: rgb(194, 14, 14); */
    gap: 5px;
  }
  
  .form-input {
    width: 100%;
    height: 40%;
    background: #f1f1f1;
    border-radius: 3px;
    border: none;
  }
  
  .form-text {
    width: 100%;
    height: 100%;
    background: #f1f1f1;
    border-radius: 5px;
    border: none;
    resize: none;
  }
  
  .lable {
    text-align: left;
  }
  
  .contact-submit {
    background: var(--template-color);
    border-radius: 7px;
    border: none;
    padding: 5px 20px 5px 20px;
    font: var(--primary-font);
    font-style: normal;
    font-weight: 700;
    font-size: var(--font-size-4);
    color: var(--secondary-font-color);
    cursor: pointer;
    margin-top: 15px;
  }

  .contact-error{
    color: #e64848;
    font-size: var(--font-size-5);
}

@media only screen and (max-device-width: 760px) {
  .contact-details-main-container{
      height: fit-content;
      flex-direction: column-reverse;
      width: 100%;
  }
  .contact-form-input-warp{
    width: 70%;
  }
  .contact-details-main-right{
    width: 100%;
  }
  .contact-details-main-left{
    width: 100%;
  }
  .contact-details-main-left-warp{
    align-items: center;
  }
  .contact-details-content{
    text-align: center;
  }
  .aboutsection1-left-container, .aboutsection1-right-container{
      width: 100%;
  }
}