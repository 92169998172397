.admin-category-main-container{
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content:center;
}

.admin-category-container{
    height: 100%;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* background: rgb(181, 51, 51); */
}

.admin-category-top-container{
    height: 50%;
    width: 100%;
    /* background: rgb(26, 143, 70); */
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.admin-category-top-left{
    height: 98%;
    width: 48%;
    /* background: rgb(126, 49, 161); */
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    box-shadow:  20px 20px 60px #d9d9d9,
             -20px -20px 60px #ffffff;
}

.admin-category-top-right{
    height: 98%;
    width: 48%;
    /* background: rgb(126, 49, 161); */
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    box-shadow:  20px 20px 60px #d9d9d9,
             -20px -20px 60px #ffffff;}

.admin-category-bottom-container{
    height: 50%;
    width: 100%;
    /* background: rgb(26, 124, 143); */
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.admin-category-bottom-left{
    height: 98%;
    width: 48%;
    gap: 10px;
    overflow-y: scroll;
    /* background: rgb(126, 49, 161); */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background: #ffffff;
    box-shadow:  20px 20px 60px #d9d9d9,
             -20px -20px 60px #ffffff;
}

.admin-category-bottom-right{
    height: 98%;
    width: 48%;
    gap: 10px;
    overflow-y: scroll;
    /* background: rgb(126, 49, 161); */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background: #ffffff;
    box-shadow:  20px 20px 60px #d9d9d9,
             -20px -20px 60px #ffffff;
}