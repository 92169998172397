.admin-product-read-main-container{
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content:center;
}

.admin-product-read-container{
    margin: 2%;
    height: 98%;
    width: 80%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    overflow-y: scroll;
    gap: 10px;
    /* background: rgb(181, 51, 51); */
}