.header-main-container{
    height: 15vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    z-index: 10;
    background: #fff;
    transition: 0.2s;
}

.header-logo-container{
    /* width: 10%; */
    padding: 10px;
    font: var(--primary-font);
    font-weight: 900;
    color: var(--primary-font-color);
    display:flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
    /* background: #000; */
}

.logo{
    /* width: 30px; */
    height: 60px;
}

.header-menu-container{
    width: 35%;
    display: flex;
    /* gap: 35px; */
    align-items: center;
    justify-content: center;
    /* padding: 15px; */
}

.header-menu-item{
    font: var(--primary-font);
    font-weight: 500;
    cursor: pointer;
    color: var(--primary-font-color);
    font-size: var(--font-size-4);
}

.header-menu-item:hover {
    /* background: var(--template-color); */
    color: var(--template-color);
    font-weight: 900;
    font-size: var(--font-size-2);
    /* padding: 10px; */
    /* border-radius: 10px; */
  }

  .header-menu-item:active {
    /* background: var(--template-color); */
    color: var(--template-color);
    font-weight: 900;
    font-size: var(--font-size-2);
    /* padding: 10px; */
    /* border-radius: 10px; */
  }

  @media only screen and (max-device-width: 760px) {
    .header-main-container{
        height: 8vh;
    }
}